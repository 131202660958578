import {action, observable} from 'mobx';
import {http} from "../utils/http";

export class Plate {
	@observable plateHistory = [];
	@observable historyCar = [];
	@observable detectedPlate = {
		plate_number: '',
		color: '',
		process_time: 0,
		image_path: '',
		coordinates: [],
		img_height: 0,
		img_width: 0,
	};
	@observable errorText = '';
	@observable showResult = false;

	@action
	async createHistory({
		plate_number,
		color,
		image_path
							}) {
		await http.post('/plate_history').send({
			plate_number,
			color,
			image_path
		});

		await this.getHistory();
	}

	@action
	async getHistory() {
		const resp = await http.get('/plate_history');
		this.plateHistory = resp.body.data;
	};

	@action
	resetState() {
		this.showResult = false;
		this.detectedPlate = {
			plate_number: '',
			color: '',
			process_time: '',
			image_path: '',
		};
		this.errorText = '';
	}

	@action
	async detectImage(imagePath) {
		this.resetState();
		const resp = await http.post('/detect').send({
			image_path: imagePath,
			country: 'us'
		});

		if(resp.body.error) {
			if(resp.body.error.includes('Temporary rate limit exceeded')) {
				const matches = /next (\d+) second/gm.exec(resp.body.error);

				if(!matches) {
					this.errorText = resp.body.error;
					this.showResult = true;
					return;
				}

				this.errorText = `Please try again in ${(+matches[1]) + 1} second.`;
				this.showResult = true;
			} else {
				this.errorText = resp.body.error;
				this.showResult = true;
			}
			return;
		}

		const data = resp.body;

		if(!data.results[0]) {
			this.errorText = 'Plate number not recognized';
			this.showResult = true;
			return;
		}

		this.detectedPlate = {
			image_path: imagePath,
			color: data.results[0].vehicle.color[0].name,
			plate_number: data.results[0].plate,
			process_time: data.processing_time.total,
			coordinates: data.results[0].coordinates,
			img_height: data.img_height,
			img_width: data.img_width,
		};

		this.showResult = true;

		await this.createHistory({
			plate_number: data.results[0].plate,
			color: data.results[0].vehicle.color[0].name,
			image_path: imagePath
		});
	}

	@action
	async getHistoryCar() {
		const resp = await http.get('/detection');
		console.log(resp, 'resp');
		this.historyCar = resp.body.data;
		console.log(this.historyCar, 'this.historyCar');
	};
}
