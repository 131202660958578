import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Inquiry} from "../pages/Inquiry/Inquiry";
import {Master} from "../pages/Master/Master";
import {Dashboard} from "../pages/Dashboard/Dashboard";
import {Reconcile} from "../pages/Reconcile/Reconcile";
import {Detection} from "../pages/Detection/Detection";
import {CarIn} from "../pages/CarIn/CarIn";
import {CarOut} from "../pages/CarOut/CarOut";
import {MatchingResult} from "../pages/MatchingResult/MatchingResult";
import {DeviceStatus} from "../pages/DeviceStatus/DeviceStatus";
import {LiveCamPage} from "../pages/LiveCam/LiveCamPage";
import {ManifestPage} from "../pages/Manifest/ManifestPage";
import {ParkingSpotPage} from "../pages/ParkingArea/ParkingSpotPage";

export const AppRoutes = () => {
  return <Switch>
    <Route path="/app" exact>
      <Redirect to={"/app/dashboard"}/>
    </Route>
    <Route path="/app/dashboard" exact>
      <Dashboard/>
    </Route>
    <Route path="/app/parking_spot" exact>
      <ParkingSpotPage />
    </Route>
    <Route path="/app/manifests" exact>
      <ManifestPage />
    </Route>
    <Route path="/app/live_camera" exact>
      <LiveCamPage/>
    </Route>
    <Route path="/app/detection" exact>
      <Detection/>
    </Route>
    <Route path="/app/carin" exact>
      <CarIn/>
    </Route>
    <Route path="/app/carout" exact>
      <CarOut/>
    </Route>
    <Route path="/app/matchingresult" exact>
      <MatchingResult/>
    </Route>
    <Route path="/app/device_status" exact>
      <DeviceStatus/>
    </Route>
    {/*<Route path="/app/reconcile" exact>*/}
    {/*  <Reconcile/>*/}
    {/*</Route>*/}
    {/*<Route path="/app/master" exact>*/}
    {/*  <Master/>*/}
    {/*</Route>*/}
    {/*<Route path="/app/inquiry" exact>*/}
    {/*  <Inquiry/>*/}
    {/*</Route>*/}
  </Switch>
};
