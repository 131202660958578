import React from 'react';
import {Card, CardBody} from "reactstrap";
import {Col, Row, Typography} from "antd";
import {CarFilled} from "@ant-design/icons";

export const InfoCard = (props) => {
    const {
        title,
        count,
        bgColor,
        icon
    } = props;
    return (
        <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10, cursor: 'pointer'}} onClick={props.onClick}>
            <CardBody className={'d-flex flex-row align-items-center flex-0'}>
                <div className="flex">
                    <Row>
                        <Col span={12} style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'}}>
                            <div style={{
                                // backgroundColor: desc,
                                backgroundColor: bgColor || '#fff1d4',
                                borderRadius: '50%',
                                width: '70px',
                                height: '70px',
                            }}>
                                {icon || <CarFilled style={{color: 'white', fontSize: '30px', padding: '20px'}} />}
                            </div>
                        </Col>
                        <Col span={12}>
                            <Typography.Title style={{
                                fontSize: 40,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginBottom: 0,
                            }}>{count}</Typography.Title>
                            <div className="d-flex flex-row align-items-start flex-1 w-100 justify-content-between">
                                <div className="d-flex flex-1 ">
                                    <p className="mb-0" style={{display: 'flex', justifyContent: 'flex-start' }}><strong>{title}</strong>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    )
}
