import React from 'react';
import {Card, CardBody} from "reactstrap";
import {Col, Row, Tag, Typography} from "antd";
import {CarFilled} from "@ant-design/icons";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {observer} from "mobx-react-lite";
import Flag from "react-world-flags";
import {format} from "date-fns";
import {startCase} from "lodash";
export const ParkingSpotCard = observer((props) => {
    const classes = useStyles();
    const {
        title,
        count,
        bgColor,
        icon
    } = props;
    let extraStyles = {

    }

    if(props.status === 'overtime'){
        extraStyles = {border: '2px solid red'}
    }
    return (
        <Paper style={{width:'100%', ...extraStyles, minHeight:'250px'}} className={classes.paper}>
            <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10}} >
                <CardBody className={'d-flex flex-row align-items-center flex-0'} >
                    <div className="flex">
                        <Row>
                            Spot:&nbsp;<span style={{color:'black'}}>A{props.indexData}</span>
                        </Row>
                        <Row>
                            <div style={{color:'black', fontWeight:'bold', fontSize:'20px'}}>{props.name} <Flag code={ 'ID' } height="16"/></div>
                        </Row>
                        <Row>
                            <span>{props.ship_owner}</span>
                        </Row>
                        <Row>
                            <span>Waktu Datang :&nbsp;<span style={{color:'black'}}>{format(new Date(props.arrival_date), 'dd/MM/yyyy HH:mm:ss')}</span></span>
                        </Row>
                        <Row>
                            <span>Waktu Berangkat :&nbsp;<span style={{color:'black'}}>{format(new Date(props.departure_date), 'dd/MM/yyyy HH:mm:ss')}</span></span>
                        </Row>
                        <Row style={{marginTop:5}}>
                            {
                                (props.status === 'on_board') && <Tag color="green">{startCase(props.status)}</Tag>
                            }
                            {
                                (props.status === 'pending') && <Tag color="gold">{startCase(props.status)}</Tag>
                            }
                            {
                                (props.status === 'overtime') && <Tag color="red">{startCase(props.status)}</Tag>
                            }
                        </Row>
                        {
                            props.status === 'overtime' && (
                                <div>
                                    <Row>
                                        Durasi overtime :&nbsp;<span style={{color:'red'}}>{props.overtime_duration}</span>
                                    </Row>
                                    <Row>
                                        Perkiraan extra charge :&nbsp;<span style={{color:'red'}}>{props.overtime_charge}</span>
                                    </Row>
                                </div>
                            )
                        }
                    </div>
                </CardBody>
            </Card>
        </Paper>
    )
})

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius: 5
    },
}));
