import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';

export const DeviceStatus = observer(() => {
	const store = useStore();

	const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');


	const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
	};

	const handleOk = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	useEffect(() => {
		// store.deviceStatus.getData();
	}, []);

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'ID',
			dataIndex: 'device_id',
			key: 'device_id',
		},
		{
			title: 'Last Seen',
			key: 'updated_at',
			dataIndex: 'updated_at',
			render: (text) => {
				return format(new Date(text), 'dd/MM/yyyy HH:mm:ss');
			}
		},
	];

	return <div>
		<Typography.Title level={2}>Device Status</Typography.Title>
		<Row>
			<Col span={24}>
				<Table scroll={{ x: 'calc(50vh - 4em)' }} dataSource={store.deviceStatus.data.slice()} columns={columns} size="middle" style={{width: '100%'}} />
			</Col>
		</Row>
	</div>;
});
