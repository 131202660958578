import {action, observable} from 'mobx';
import {http} from "../utils/http";

export class Dashboard {
    @observable data = {
        "match_count": 0,
        "in_count": 0,
        "out_count": 0
    };

    @observable dataQuality = {
        "valid": 0,
        "valid_percentage": 0,
        "invalid": 0,
        "invalid_percentage": 0,
        "partial_valid": 0,
        "partial_valid_percentage": 0,
        "pending": 0,
        "pending_percentage": 0,
        "total_count": 0,
    };

    @observable dataMatching = [];

    @action
    async getData() {
        const resp = await http.get('/dashboard');
        const respQuality = await http.get('/dashboard/quality');

        const respMatchingResult = await http.get('/matching_result?pageSize=5');

        this.data = resp.body;
        this.dataMatching = respMatchingResult.body.data;
        this.dataQuality = respQuality.body;
        console.log("this.data", this.data);
    }

}
