import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal, Select, message, Tag} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, EyeOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";
import {startCase} from 'lodash';
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import download from 'downloadjs';
import Flag from 'react-world-flags'
const MAX_IMAGE_WIDTH = 305;
const MAX_IMAGE_HEIGHT = 300;


export const ManifestPage = observer(() => {
	const store = useStore();

	const [plateNumber, setPlateNumber] = useState('');
	const [color, setColor] = useState('');
	const [processingTime, setProcessingTime] = useState(0);
	const [showResult, setShowResult] = useState(false);
	const [error, setError] = useState('');
	const [imagePath, setImagePath] = useState('');
	const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');

	const resetView = () => {
		if(showResult) {
			setError('');
			setPlateNumber('');
			setColor('');
			setProcessingTime(0);
			setImagePath('');
			setShowResult(false);
		}
	};

	const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
	};

	const handleOk = e => {
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	useEffect(() => {
		// store.cars.setPageCarsIn();
	}, []);

	const columns = [
		{
			title: 'Nama Kapal',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Pemilik',
			dataIndex: 'ship_owner',
			key: 'ship_owner',
		},
		{
			title: 'Asal kapal',
			dataIndex: 'ship_origin',
			key: 'ship_origin',
			render: (text) => {
				return <div>
					<Flag code={ text } height="16"/> {text}
				</div>
			}
		},
		{
			title: 'Waktu Datang',
			key: 'arrival_date',
			dataIndex: 'arrival_date',
			render: (text) => {
				return format(new Date(text), 'dd/MM/yyyy HH:mm:ss');
			}
		},
		{
			title: 'Waktu Berangkat',
			key: 'departure_date',
			dataIndex: 'departure_date',
			render: (text) => {
				return format(new Date(text), 'dd/MM/yyyy HH:mm:ss');
			}
		},
		{
			title: 'Berat Muatan',
			key: 'cargo_weight',
			dataIndex: 'cargo_weight',
		},
		{
			title: 'Jenis Muatan',
			key: 'cargo_type',
			dataIndex: 'cargo_type',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, data) => {
				return <div>
					{
						(text === 'on_board') && <Tag color="green">{startCase(text)}</Tag>
					}
					{
						(text === 'pending') && <Tag color="gold">{startCase(text)}</Tag>
					}
					{
						(text === 'overtime') && <Tag color="red">{startCase(text)}</Tag>
					}
				</div>
			}
		},
		{
			title: 'Aksi',
			key: 'action',
			dataIndex: 'action',
			render: ()=>{
				return (
					<div>
						<Button icon={<EyeOutlined />} shape={'circle'} />
					</div>
				)
			}
		},

	];

	return <div>
		<div style={{
			display: "flex",
			flexDirection: 'row',
			justifyContent: 'space-between'
		}}>
			<Typography.Title level={2}>Manifests</Typography.Title>
			<Button type="primary" onClick={async () => {
				// message.info('Generating data, please wait');
				// const data = await http.get(`/export/input`);
				//
				// const x = new XMLHttpRequest();
				// x.open("GET", appConfig.apiUrl + data.body.filepath, true);
				// x.responseType = 'blob';
				// x.onload = function(e){
				// 	download(x.response, 'car_in_data.zip', 'application/zip');
				// };
				// x.send();
			}}>Export</Button>
		</div>
		<Row>
			<Col span={24}>
				<Table scroll={{ x: 'calc(50vh - 4em)' }}
					   dataSource={store.cars.manifest.slice()}
					   columns={columns}
					   size="middle"
					   style={{width: '100%'}}
					   pagination={{
					   		// total: store.cars.totalPageCarsIn,
					   		total: 10,
						   showSizeChanger: false
					   }}
					   current={store.cars.currentPageCarsIn}
					   onChange={(page) => {
						   store.cars.setPageCarsIn(page.current);
					   }}
				/>
			</Col>
			<Modal
				title="Image"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<img src={appConfig.apiUrl + link} alt="" style={{
					width: '100%',
					height: '100%',
					padding: '10%',
					borderRadius: 15,
				}}/>
			</Modal>
		</Row>
	</div>;
});
