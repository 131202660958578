import {action, observable} from 'mobx';
import {http} from "../utils/http";

export class DeviceStatus {
    @observable data = [ {
        "id":"e0822928-b486-479f-be6a-a7e8020bb2da",
        "device_id":"cam_main_1",
        "type":"Main Camera",
        "created_at":"2020-11-23T07:36:24.951Z",
        "updated_at":"2020-12-05T11:42:48.302Z",
        "deleted_at":null
    },
        {
            "id":"253e3693-7df5-4703-b343-5a88d368fad2",
            "device_id":"cam_gate_1",
            "type":"Gate 1",
            "created_at":"2020-11-23T07:34:27.374Z",
            "updated_at":"2020-12-05T11:42:48.419Z",
            "deleted_at":null
        },
        {
            "id":"dc6159d1-9ca4-42b7-af40-d089b12d1e68",
            "device_id":"cam_gate_2",
            "type":"Gate 2",
            "created_at":"2020-11-23T07:34:27.273Z",
            "updated_at":"2020-12-05T11:42:48.208Z",
            "deleted_at":null
        }];

    @action
    async getData() {
        const resp = await http.get('/heartbeat');

        this.data = resp.body.data;
    }
}
