import React, {useRef} from 'react';
import {observer} from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row, Tabs, Typography} from 'antd';
import 'video.js/dist/video-js.css';
import videojs from "video.js";
import VideoJs from "../../component/VideoJS";
import {Card, CardBody} from "reactstrap";
import {AreaChartOutlined, BookOutlined, ClockCircleFilled, HeatMapOutlined} from "@ant-design/icons";
import {InfoCard} from "../LiveCam/InfoCard";
import {faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom';
export const LiveCCTV = observer(()=>{
    const classes = useStyles();
    const playerRef = useRef(null);
    const history = useHistory();
    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    }

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: 'https://files-api.app.bangun-kreatif.com/cdi/Ship1_720p.mp4',
            type: 'video/mp4'
        }]
    };

    const videoJsOptions2 = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: 'https://files-api.app.bangun-kreatif.com/cdi/Ship2.mp4',
            type: 'video/mp4'
        }]
    };
    return (
        <Grid container spacing={2} style={{paddingTop: '10px'}}>
            <Grid item xs={12} sm={8}>
                <Paper className={classes.paper}>
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Main Camera" key="1">
                            <VideoJs
                                options={videoJsOptions}
                                onReady={handlePlayerReady}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Gate 1" key="2">
                            <VideoJs
                                options={videoJsOptions2}
                                onReady={handlePlayerReady}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Row style={{marginBottom: 10}}>
                    <Paper style={{width:'100%'}} className={classes.paper}>
                        <InfoCard
                            bgColor={'#57dffa'}
                            onClick={()=>history.push('/app/parking_spot')}
                            icon={<AreaChartOutlined style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                            title={'Current Ships on Port'} count={10}/>
                    </Paper>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Paper style={{width:'100%'}} className={classes.paper}>
                        <InfoCard
                            bgColor={'#4caf50'}
                            // icon={<ClockCircleFilled style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                            icon={<FontAwesomeIcon icon={faMapMarker} style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                            title={'Parking Spot Available'}
                            onClick={()=>history.push('/app/parking_spot')}
                            count={"2/10"}/>
                    </Paper>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Paper style={{width:'100%'}} className={classes.paper}>
                        <InfoCard
                            onClick={()=>history.push('/app/manifests')}
                            icon={<BookOutlined style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                            bgColor={'#f7bb59'} title={'Total ships docked today'} count={15}/>
                    </Paper>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Paper style={{width:'100%'}} className={classes.paper}>
                        <InfoCard
                            bgColor={'#f44336'}
                            onClick={()=>history.push('/app/parking_spot')}
                            icon={<ClockCircleFilled style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                            title={'Total Ships overtime'}
                            count={2}/>
                    </Paper>
                </Row>
            </Grid>
        </Grid>
    )
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius: 5
    },
}));
