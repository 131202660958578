import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal, Select, message} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import download from "downloadjs";

const MAX_IMAGE_WIDTH = 305;
const MAX_IMAGE_HEIGHT = 300;


export const CarOut = observer(() => {
	const store = useStore();

	const [plateNumber, setPlateNumber] = useState('');
	const [color, setColor] = useState('');
	const [processingTime, setProcessingTime] = useState(0);
	const [showResult, setShowResult] = useState(false);
	const [error, setError] = useState('');
	const [imagePath, setImagePath] = useState('');
	const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');

	const resetView = () => {
		if(showResult) {
			setError('');
			setPlateNumber('');
			setColor('');
			setProcessingTime(0);
			setImagePath('');
			setShowResult(false);
		}
	};

	const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
	};

	const handleOk = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	useEffect(() => {
		store.cars.setPageCarsOut(1);
	}, []);

	const columns = [
		{
			title: 'Plate Number',
			dataIndex: 'plate_number',
			key: 'plate_number',
			// render: text => <a>{text}</a>,
			render: text => {
				return(
					<div>{text}</div>
				);
			},
		},
		{
			title: 'Color',
			dataIndex: 'color',
			key: 'color',
		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
		},
		{
			title: 'Model',
			dataIndex: 'model',
			key: 'model',
		},
		{
			title: 'Probability Model',
			dataIndex: 'model',
			key: 'model',
			render: (text, data) => {
				return <div>{(+data.original_data.prob).toFixed(3)}</div>
			}
		},
		{
			title: 'Probability Plate',
			dataIndex: 'model',
			key: 'model',
			render: (text, data) => {
				return <div>{(+(data.plate_confidence || 0) / 100).toFixed(3)}</div>
			}
		},
		{
			title: 'Execution time',
			dataIndex: 'model',
			key: 'model',
			render: (text, data) => {
				return <div>{(data.original_data.detection_time).toFixed(3)}s</div>
			}
		},
		{
			title: 'Image',
			dataIndex: 'image_path',
			key: 'image_path',
			// render: text => <a href={appConfig.apiUrl + text} target={'_blank'}>Image</a>,
			render: (text) => {
				console.log(text, 'textttttttt');
				console.log(appConfig.apiUrl + text, 'appConfig.apiUrl + text');
				return(
				<>
					<Button onClick={()=>showModal(text)}>Image</Button>

				</>);
			}
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			render: (text, data) => {
				return <div>
					<Select
						showSearch
						style={{ width: 200 }}
						placeholder="Select a person"
						optionFilterProp="children"
						value={data.quality}
						onChange={async (value) => {
							await store.cars.updateQuality(data.id, value);
							await store.cars.getCarsOut();
						}}
					>
						<Select.Option value="pending">Pending</Select.Option>
						<Select.Option value="invalid">Invalid</Select.Option>
						<Select.Option value="partial_valid">Partially Valid</Select.Option>
						<Select.Option value="valid">Valid</Select.Option>
					</Select>
				</div>
			}
		},
		{
			title: 'Datetime',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text) => {
				return format(new Date(text), 'dd/MM/yyyy HH:mm:ss');
			}
		},
	];

	return <div>
		<div style={{
			display: "flex",
			flexDirection: 'row',
			justifyContent: 'space-between'
		}}>
			<Typography.Title level={2}>Ship Out</Typography.Title>
			<Button type="primary" onClick={async () => {
				message.info('Generating data, please wait');
				const data = await http.get(`/export/output`);

				const x = new XMLHttpRequest();
				x.open("GET", appConfig.apiUrl + data.body.filepath, true);
				x.responseType = 'blob';
				x.onload = function(e){
					download(x.response, 'car_out_data.zip', 'application/zip');
				};
				x.send();
			}}>Export</Button>
		</div>
		<Row>
			<Col span={24}>
				<Table scroll={{ x: 'calc(50vh - 4em)' }} dataSource={store.cars.cars_out.slice()} columns={columns} size="middle" style={{width: '100%'}}

					   pagination={{
						   total: store.cars.totalPageCarsOut,
						   showSizeChanger: false
					   }}
					   current={store.cars.currentPageCarsOut}
					   onChange={(page) => {
						   store.cars.setPageCarsOut(page.current);
					   }}
				/>
			</Col>
			<Modal
				title="Image"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<img src={appConfig.apiUrl + link} alt="" style={{
					width: '100%',
					height: '100%',
					padding: '10%',
					borderRadius: 15,
				}}/>
			</Modal>
		</Row>
	</div>;
});
