import {action, observable} from 'mobx';
import {Plate} from "./plate";
import {Cars} from "./cars";
import {DeviceStatus} from "./device_status";
import {Dashboard} from "./dashboard";

export class Store {
	plate = new Plate();
	cars = new Cars();
	deviceStatus = new DeviceStatus();
	dashboard = new Dashboard();

  @observable testObs = 'umu';

  @action
  setTestObs() {
    this.testObs = 'qwfp';
    console.log(this.testObs, 'this.testObs');
  };
}
