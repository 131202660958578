import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal, Select, message} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import download from 'downloadjs';
import {LiveCCTV} from "../Dashboard/LiveCCTV";



export const LiveCamPage = observer(() => {
	const store = useStore();



	useEffect(() => {
		store.cars.setPageCarsIn();
	}, []);


	return <div>
		<div style={{
			display: "flex",
			flexDirection: 'row',
			justifyContent: 'space-between'
		}}>
			<Typography.Title level={2}>Live Camera</Typography.Title>
		</div>
		<Row>
			<LiveCCTV />
		</Row>
	</div>;
});
