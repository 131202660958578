import React, {useRef} from 'react';
import {observer} from "mobx-react-lite";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row, Tabs, Typography} from 'antd';
import 'video.js/dist/video-js.css';
import videojs from "video.js";
import VideoJs from "../../component/VideoJS";
import {Card, CardBody} from "reactstrap";
import {AreaChartOutlined, BookOutlined, ClockCircleFilled, HeatMapOutlined} from "@ant-design/icons";
import {InfoCard} from "../LiveCam/InfoCard";
import {ParkingSpotCard} from "./ParkingSpotCard";
import {useStore} from "../../utils/useStores";

export const ParkingSpotPage = observer(()=>{
    const classes = useStyles();
    const store = useStore();
    const playerRef = useRef(null);
    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    }
    return (
        <Grid container spacing={2} style={{paddingTop: '10px'}}>
            {
                store.cars.manifest.slice().map((prop,index) => {
                    return (
                        <Grid item xs={12} sm={4} key={index}>
                            <ParkingSpotCard
                                {
                                    ...prop
                                }
                                indexData={index}
                                bgColor={'#57dffa'}
                                icon={<AreaChartOutlined style={{color: 'white', fontSize: '30px', padding: '20px'}}/>}
                                title={'Current Ships on Port'} count={10}/>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderRadius: 5
    },
}));
