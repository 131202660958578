import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {format} from "date-fns";
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';  
import { makeStyles } from '@material-ui/core/styles';

const MAX_IMAGE_WIDTH = 305;
const MAX_IMAGE_HEIGHT = 300;


export const Detection = observer(() => {
	const classes = useStyles();
	const store = useStore();

	const [plateNumber, setPlateNumber] = useState('');
	const [color, setColor] = useState('');
	const [processingTime, setProcessingTime] = useState(0);
	const [showResult, setShowResult] = useState(false);
	const [error, setError] = useState('');
	const [imagePath, setImagePath] = useState('');
	const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');
	const [pointLine, setPointLine] = useState('');
	const [changeWidth, setChangeWidth] = useState(MAX_IMAGE_WIDTH);
	const [changeHeight, setChangeHeight] = useState(MAX_IMAGE_HEIGHT);

	const resetView = () => {
		if(showResult) {
			setError('');
			setPlateNumber('');
			setColor('');
			setProcessingTime(0);
			setImagePath('');
			setShowResult(false);
		}
	};

	const PointImage = (data) => {
		let image_width = store.plate.detectedPlate.img_width;
		let image_height = store.plate.detectedPlate.img_height;
		let reference_width = MAX_IMAGE_WIDTH;
		let reference_height = MAX_IMAGE_HEIGHT;
		let ratio = Math.max(image_width / reference_width, image_height / reference_height);
		let new_width = image_width / ratio;
		let new_height = image_height / ratio;

		const [image] = useImage(appConfig.apiUrl + store.plate.detectedPlate.image_path);
		console.log(ratio, new_height, new_width, 'yoyo');
		setChangeWidth(new_width);
		setChangeHeight(new_height);
		return <Image image={image} width={new_width} height={new_height} />;
	};

	const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
	};

	const handleOk = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	useEffect(() => {
		store.plate.resetState();
		store.plate.getHistory();
		store.plate.getHistoryCar();
	}, []);

	const columns = [
		{
			title: 'Plate Number',
			dataIndex: 'plate_number',
			key: 'plate_number',
			// render: text => <a>{text}</a>,
			render: text =>{
				setPlateNumber(text);
				return(
					<div>{text}</div>
				);
			},
		},
		{
			title: 'Color',
			dataIndex: 'color',
			key: 'color',
		},
		{
			title: 'Link Image',
			dataIndex: 'image_path',
			key: 'image_path',
			// render: text => <a href={appConfig.apiUrl + text} target={'_blank'}>Image</a>,
			render: (text) => {
				console.log(text, 'textttttttt');
				console.log(appConfig.apiUrl + text, 'appConfig.apiUrl + text');
				return(
				<>
					<Button onClick={()=>showModal(text)}>Image</Button>

				</>);
			}
		},
		{
			title: 'Scanned At',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text) => {
				return format(new Date(text), 'HH:mm:ss dd/MM/yyyy');
			}
		},
	];

	const loopLine = () => {
	  	let ChangeLine = store.plate.detectedPlate;
	  	let coordinates = ChangeLine.coordinates;

		let maxWidth = MAX_IMAGE_WIDTH;
		let maxHeight = MAX_IMAGE_HEIGHT;
		let width = store.plate.detectedPlate.img_width;
		let height = store.plate.detectedPlate.img_height;
		let ratio = Math.max( width / maxWidth, height / maxHeight);
		console.log(ratio, 'ratio');
		// console.log(store.plate.detectedPlate.coordinates[0].x * (maxWidth / width),store.plate.detectedPlate.coordinates[0].y * (maxHeight / height) , 'changeeee')
		// console.log(store.plate.detectedPlate.coordinates, 'coor')


		let point = [];

		coordinates.push(coordinates[0]);
		let dataLine = coordinates.map((v, key) => {
			point.push(v.x / ratio)
			point.push(v.y / ratio)
		})
		setPointLine(point);
	}

	return <div>
		<Typography.Title level={2}>Plate Number Detection</Typography.Title>
		<Grid container spacing={2}>
			<Grid item xs={12} sm={3}>
				<Row>
					<Col span={24}>
						<div style={{
							backgroundColor: '#515257',
							height: '615px',
							shadowColor: '#000',
							shadowOffset: {width: 0.5, height: 0.5},
							shadowOpacity: 0.5,
							shadowRadius: 3,
							elevation: 5,
							padding: '0.2rem',
						}} scroll={{ x: 'calc(50vh - 4em)' }}>
							{store.plate.showResult && !store.plate.errorText && <>
							<Row>
								<Col span={24}
									 style={{
										 padding: '10px'
									 }}>
									<Upload name="logo" action="#" showUploadList={false} customRequest={http.uploadAntd} onChange={async (event) => {
										if(event && event.fileList && event.fileList.length) {
											resetView();

											const newFile = event.fileList[event.fileList.length - 1];

											if(newFile.status === 'done') {

												notification.open({
													message: 'Processing image',
													description: '',
													onClick: () => {
														// console.log('Notification Clicked!');
													},
												});

												await store.plate.detectImage(newFile.response.body.path);
												loopLine();
											}
										}
										// store.reconcile_history.importData();
									}}>
										<Button icon={<UploadOutlined />} style={{backgroundColor: '#fbc42c'}}>Upload image</Button>
									</Upload>
								</Col>
							</Row>
							</>}
							{store.plate.showResult && !store.plate.errorText && <>
								<Row>
									<Col span={24}
										 style={{
											 marginTop: '50px',
											 paddingLeft: '10px',
											 display: 'flex',
											 justifyContent: 'center',
											 alignItems: 'center'
										 }}>
										<Stage width={changeWidth} height={changeHeight}>
											<Layer>
												<PointImage width={'100%'} />
												<Line
													x={0}
													y={0}
													points={pointLine}
													stroke="red"
												/>
											</Layer>
										</Stage>
									</Col>
								</Row>
								<Row>
									<Col span={24} style={{display: 'flex', justifyContent: 'center', marginTop: 32}}>
										<div style={{
											backgroundColor: 'black',
											height: '70px',
											width: '200px',
											borderRadius: 15,
											borderColor: 'white',
											borderWidth: 5,
											padding: '0.2rem',
										}}>
											<div style={{
												height: '100%',
												width: '100%',
												borderRadius: 15,
												borderColor: 'white',
												borderWidth: 5,
												borderStyle: 'solid',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}>
												<Typography.Text style={{
													color: 'white',
													fontSize: 20,
												}}>{store.plate.detectedPlate.plate_number}
												</Typography.Text>
											</div>
										</div>
									</Col>
								</Row>
								<Row>
									<Col span={24} style={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '5px',
										alignItems: 'center'
									}}>
										<Typography.Text
											style={{
												fontSize: 15,
												color: 'white',
												fontFamily: 'Arial, Helvetica, sans-serif',
											}}>Color : {store.plate.detectedPlate.color}</Typography.Text>
									</Col>
								</Row>
								<Row>
									<Col span={24} style={{
										display: 'flex',
										justifyContent: 'center',
										marginTop: '5px',
										alignItems: 'center'
									}}>
										<Typography.Text
											style={{
												fontSize: 15,
												color: 'white',
												fontFamily: 'Arial, Helvetica, sans-serif',
											}}>Processed in : {store.plate.detectedPlate.process_time.toFixed(2)}ms</Typography.Text>
									</Col>
								</Row>
							</>}

							{store.plate.showResult && store.plate.errorText && <Row>
								<Col span={24} style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '200px',
								}}>
									<Typography.Text style={{fontSize: 15, color: 'white'}}>{store.plate.errorText}</Typography.Text>
								</Col>
							</Row>}


							{!store.plate.showResult && <Row>
								<Col span={24} style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '250px',
									alignItems: 'center'
								}}>
									<Upload name="logo" action="#" showUploadList={false} customRequest={http.uploadAntd} onChange={async (event) => {
										if(event && event.fileList && event.fileList.length) {
											resetView();

											const newFile = event.fileList[event.fileList.length - 1];

											if(newFile.status === 'done') {

												notification.open({
													message: 'Processing image',
													description: '',
													onClick: () => {
														// console.log('Notification Clicked!');
													},
												});

												await store.plate.detectImage(newFile.response.body.path);
												loopLine();
											}
										}
										// store.reconcile_history.importData();
									}}>
										<Button icon={<UploadOutlined />} style={{backgroundColor: '#fbc42c'}}>Upload image</Button>
									</Upload>
								</Col>
							</Row>}
						</div>
					</Col>
				</Row>
			</Grid>
			<Grid item xs={12} sm={9}>
				<Table scroll={{ x: 'calc(50vh - 4em)' }} dataSource={store.plate.historyCar.slice()} columns={columns} size="middle" style={{width: '100%'}} />
			</Grid>

			<Modal
				title="Image"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<img src={appConfig.apiUrl + link} alt="" style={{
					width: '100%',
					height: '100%',
					padding: '10%',
					borderRadius: 15,
				}}/>
			</Modal>		
		</Grid>
	</div>;
});

const useStyles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));