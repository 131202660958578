import React, {useEffect, useState} from "react";
import {Typography, notification, Upload, Button, Row, Col, Table, Modal, Select, message} from "antd";
import {http} from "../../utils/http";
import {
	DownloadOutlined, UploadOutlined,
} from '@ant-design/icons';
import {appConfig} from "../../config/app";
import {useStore} from "../../utils/useStores";
import {observer} from "mobx-react-lite";
import {differenceInHours, format} from "date-fns";
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import download from "downloadjs";
import {getPricePerHours} from "../../utils/price";

const MAX_IMAGE_WIDTH = 305;
const MAX_IMAGE_HEIGHT = 300;


export const MatchingResult = observer(() => {
	const store = useStore();

	const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');

	const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
	};

	const handleOk = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	useEffect(() => {
		store.cars.setPageMatchingCars(1);
	}, []);

	const columns = [
		{
			title: 'Plate Number',
			dataIndex: 'input_plate_number',
			key: 'input_plate_number',
			// render: text => <a>{text}</a>,
			render: (text, data) =>{
				return(
					<div>{data.input_plate_number} | {data.output_plate_number}</div>
				);
			},
		},
		{
			title: 'Color',
			dataIndex: 'color',
			key: 'color',
			render: (text, data) =>{
				return(
					<div>{data.input_color} | {data.output_color}</div>
				);
			},
		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
			render: (text, data) =>{
				return(
					<div>{data.input_brand} | {data.output_brand}</div>
				);
			},
		},
		{
			title: 'Model',
			dataIndex: 'model',
			key: 'model',
			render: (text, data) =>{
				return(
					<div>{data.input_model} | {data.output_model}</div>
				);
			},
		},
		{
			title: 'Execution time',
			dataIndex: 'match_percentage',
			key: 'match_percentage',
			render: (text, data) => {
				return (
					<div>{((+data.input_detection_time) || 0).toFixed(3)} ms | {((+data.output_detection_time) || 0).toFixed(3)} ms</div>
				);
			},
		},
		{
			title: 'Quality',
			dataIndex: 'quality',
			key: 'quality',
			render: (text, data) => {
				return <Select
					showSearch
					style={{ width: 200 }}
					placeholder="Select a person"
					optionFilterProp="children"
					value={data.output_quality}
					onChange={async (value) => {
						await Promise.all([
							store.cars.updateQuality(data.output_id, value),
							store.cars.updateQuality(data.input_id, value)
						]);
						await store.cars.getMatchingCars();
					}}
				>
					<Select.Option value="pending">Pending</Select.Option>
					<Select.Option value="invalid">Invalid</Select.Option>
					<Select.Option value="partial_valid">Partially Valid</Select.Option>
					<Select.Option value="valid">Valid</Select.Option>
				</Select>;

				return (
					<div>{data.input_quality} | {data.output_quality}</div>
				);
			},
		},
		{
			title: 'Match Percentage',
			dataIndex: 'match_percentage',
			key: 'match_percentage',
			render: (text, data) =>{
				return(
					<div>{data.match_percentage}</div>
				);
			},
		},
		{
			title: 'Image',
			dataIndex: 'image_path',
			key: 'image_path',
			// render: text => <a href={appConfig.apiUrl + text} target={'_blank'}>Image</a>,
			render: (text, data) => {
				return (<>
					<Button onClick={()=>showModal(data.input_image_path)}>Image Input</Button>
					<Button onClick={()=>showModal(data.output_image_path)}>Image Output</Button>
				</>);
			}
		},
		{
			title: 'Datetime',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text, data) => {
				return <span>{format(new Date(data.input_created_at), 'dd/MM/yyyy HH:mm:ss')} | {format(new Date(data.output_created_at), 'dd/MM/yyyy HH:mm:ss')}</span>;
			}
		},
		{
			title: "Est Price",
			key: "price",
			dataIndex: 'price',
			render: (text, data) => {
				return getPricePerHours(differenceInHours( new Date(data.output_created_at), new Date(data.input_created_at)))
			}
		}
	];

	return <div>

		<div style={{
			display: "flex",
			flexDirection: 'row',
			justifyContent: 'space-between'
		}}>
			<Typography.Title level={2}>Matching Result</Typography.Title>
			<Button type="primary" onClick={async () => {
				message.info('Generating data, please wait');
				const data = await http.get(`/export/matching_data`);

				const x = new XMLHttpRequest();
				x.open("GET", appConfig.apiUrl + data.body.filepath, true);
				x.responseType = 'blob';
				x.onload = function(e){
					download(x.response, 'matching_data.zip', 'application/zip');
				};
				x.send();
			}}>Export</Button>
		</div>
		<Row>
			<Col span={24}>
				<Table scroll={{ x: 'calc(50vh - 4em)' }} dataSource={store.cars.matching_cars.slice()} columns={columns} size="middle" style={{width: '100%'}}

					   pagination={{
						   total: store.cars.totalPageMatchingCars,
						   showSizeChanger: false
					   }}
					   current={store.cars.currentPageMatchingCars}
					   onChange={(page) => {
						   store.cars.setPageMatchingCars(page.current);
					   }}
				/>
			</Col>
			<Modal
				title="Image"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<img src={appConfig.apiUrl + link} alt="" style={{
					width: '100%',
					height: '100%',
					padding: '10%',
					borderRadius: 15,
				}}/>
			</Modal>
		</Row>
	</div>;
});
