import {action, observable} from 'mobx';
import {http} from "../utils/http";

export class Cars {
    @observable cars_in = [];
    @observable manifest = [{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Bobara",
        ship_owner: "PT. ASDP Indonesia Ferry (Persero)",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 4.5,
        cargo_type: "food",
        status: "on_board"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Uma Kalada",
        ship_owner: "PT. ASDP Indonesia Ferry (Persero)",
        ship_origin: "ID",
        arrival_date: "2022-05-19 19:42:40.023000",
        departure_date: "2022-05-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "overtime",
        overtime_duration: "2d 14h",
        overtime_charge: "Rp.14.000.000"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "41 KMP. Portlink 5",
        ship_owner: "PT. ASDP Indonesia Ferry (Persero)",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "pending"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP Trans Jawa 9 (hasil modif)",
        ship_owner: "PT. Pelayaran Makmur Bersama",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 4.5,
        cargo_type: "food",
        status: "on_board"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP Trima Jaya 9",
        ship_owner: "PT. Pelayaran Makmur Bersama",
        ship_origin: "ID",
        arrival_date: "2022-05-19 19:42:40.023000",
        departure_date: "2022-05-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "overtime",
        overtime_duration: "3d 6h",
        overtime_charge: "Rp.27.000.000"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP Tunu Pratama Jaya",
        ship_owner: "PT. Raputra Jaya",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "pending"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Adhi Swadarma III",
        ship_owner: "PT. Jembatan Nusantara",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "pending"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Berkala Prima",
        ship_owner: "PT. Berkala Prima",
        ship_origin: "ID",
        arrival_date: "2022-04-19 19:42:40.023000",
        departure_date: "2022-04-29 16:14:47.737000",
        cargo_weight: 4.5,
        cargo_type: "food",
        status: "on_board"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Barau",
        ship_owner: "PT. ASDP Indonesia Ferry (Persero)",
        ship_origin: "ID",
        arrival_date: "2022-05-19 19:42:40.023000",
        departure_date: "2022-05-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "overtime",
        overtime_duration: "1d 6h",
        overtime_charge: "Rp.7.000.000"
    },{
        id: 'be523098-8e05-4fab-b894-f7967fa2f673',
        name: "KMP. Bandeng",
        ship_owner: "PT. ASDP Indonesia Ferry (Persero)",
        ship_origin: "ID",
        arrival_date: "2022-07-19 19:42:40.023000",
        departure_date: "2022-07-29 16:14:47.737000",
        cargo_weight: 3.5,
        cargo_type: "vehicles",
        status: "pending"
    },
    ];
    @observable cars_out = [];
    @observable matching_cars = [];

    @observable currentPageCarsIn = 1;
    @observable currentPageCarsOut = 1;
    @observable currentPageMatchingCars = 1;

    @observable totalPageCarsIn = 0;
    @observable totalPageCarsOut = 0;
    @observable totalPageMatchingCars = 0;

    @action
    setPageCarsIn(page=1) {
        this.currentPageCarsIn = page;
        this.getCarsIn();
    }

    @action
    setPageCarsOut(page=1) {
        this.currentPageCarsOut = page;
        this.getCarsOut();
    }

    @action
    setPageMatchingCars(page=1) {
        this.currentPageMatchingCars = page;
        this.getMatchingCars();
    }

    @action
    async getCarsIn() {
        const resp = await http.get(`/cars?type=input&pageSize=10&page=${this.currentPageCarsIn}`);

        this.cars_in = resp.body.data;
        this.totalPageCarsIn = resp.body.total_data;
    }

    @action
    async getCarsOut() {
        const resp = await http.get(`/cars?type=output&pageSize=10&page=${this.currentPageCarsOut}`);

        this.cars_out = resp.body.data;
        this.totalPageCarsOut = resp.body.total_data;
    }

    @action
    async getMatchingCars() {
        const resp = await http.get(`/matching_result?pageSize=10&page=${this.currentPageMatchingCars}`);

        this.matching_cars = resp.body.data;
        this.totalPageMatchingCars = resp.body.total_data;
    }

    @action
    async updateQuality(id, quality) {
        await http.put(`/cars/${id}/quality`).send({
            quality
        });
    }
}
