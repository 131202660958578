import superagent from "superagent";
import {appConfig} from "../config/app";

export const http = {
  get: (url, opts = {}) => {
  	let req = superagent.get(appConfig.apiUrl + url);
  	return req;
  },
  post: (url, opts) => {
	  let req = superagent.post(appConfig.apiUrl + url);
	  return req;
  },
  put: (url, opts) => {
	  let req = superagent.put(appConfig.apiUrl + url);
	  return req;
  },
  del: (url, opts) => {
	  let req = superagent.del(appConfig.apiUrl + url);
	  return req;
  },
  upload: (file) => {
    const request = superagent
      .post(appConfig.apiUrl + '/files')
      .attach('file', file);

    return request;
  },
  uploadAntd: (args) => {
    const file = args.file;
    const request = http.upload(file);
    request
      .on('progress', event => {
        args.onProgress(event);
      })
      .then(it => {
        args.onSuccess(it);
      }).catch(err => {
      args.onError(err);
    });

    return request;
  }
};
