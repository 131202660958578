import React, {useState} from "react";
import {AppRoutes} from "../../routes/app";
import { UserOutlined, VideoCameraFilled, MenuFoldOutlined, MenuUnfoldOutlined, DashboardOutlined, CarFilled, BlockOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import {createUseStyles} from "react-jss";
import {Link} from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import {colors} from "../../config/color";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCoffee, faFileText, faMapMarker, faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faShip } from '@fortawesome/free-solid-svg-icons'
const { Header, Content, Sider } = Layout;

const useStyles = createUseStyles({
  logo: `
    height: 60px;
    // background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    display: flex,
    justify-content: center,
    align-items: center,
    text-align: center
  `,
  hideSidebarButton: `
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    `,
  hideSidebarButtonHovered: {
    '&:hover': `
      color: #1890ff;
    `
  },
  list: {
    backgroundColor: colors.menuSecondary
  },
  paper: {
    backgroundColor: '#515257 !important'
  },
});

const logo = require('../../assets/images/logo1.png');
const logoSmall = require('../../assets/images/logo1.png');


export const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('1');
  const classes = useStyles();

  const handleClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.logo} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
        <img src={logo.default} alt="" style={{
          width: '168px',
          // paddingLeft: '10px',
          // paddingRight: '10px'
        }}/>
        {/* <span style={{color: 'white', fontWeight: 700, fontSize: '15px'}}>BKA PARKING SYSTEM</span> */}
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={handleClick} selectedKeys={[current]} style={{backgroundColor: colors.menuSecondary}}>
        <Menu.Item key="1" style={{backgroundColor: current === '1' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/dashboard">
            <DashboardOutlined />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        {/*<Menu.Item key="2" style={{backgroundColor: current === '2' ? colors.primary : colors.menuSecondary}}>*/}
        {/*  <Link to="/app/detection">*/}
        {/*    <UserOutlined />*/}
        {/*    <span>Detect Plate Number</span>*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="2a" style={{backgroundColor: current === '2a' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/live_camera">
            <VideoCameraFilled />
            <span>Live Camera</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2b" style={{backgroundColor: current === '2b' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/parking_spot">
            <FontAwesomeIcon icon={faMapMarker} style={{marginRight:10}}/>
            <span>Parking Area</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3" style={{backgroundColor: current === '3' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/carin">
            <FontAwesomeIcon icon={faShip} style={{marginRight:10}}/>
            <span>Ship in</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4" style={{backgroundColor: current === '4' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/carout">
            <FontAwesomeIcon icon={faShip} style={{marginRight:10}}/>
            <span>Ship Out</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4a" style={{backgroundColor: current === '4a' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/manifests">
            <FontAwesomeIcon icon={faFileText} style={{marginRight:10}}/>
            <span>Manifest</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5" style={{backgroundColor: current === '5' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/matchingresult">
            <BlockOutlined />
            <span>Matching Result</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="5" style={{backgroundColor: current === '6' ? colors.primary : colors.menuSecondary}}>
          <Link to="/app/device_status">
            <FontAwesomeIcon icon={faVideoCamera} style={{marginRight:10}}/>
            <span>Device Status</span>
          </Link>
        </Menu.Item>
      </Menu>
  </div>
  );



  return (
    <div>
      <MobileView>
          <Header style={{ backgroundColor: '#fff', padding: 0 }}>
            <React.Fragment key={'left'}>
              <Button onClick={toggleDrawer('left', true)}>
                {!collapsed && <MenuFoldOutlined className={classes.hideSidebarButton} onClick={toggleDrawer('left', true)}/>}
                {collapsed && <MenuUnfoldOutlined className={classes.hideSidebarButton} onClick={toggleDrawer('left', false)}/>}
              </Button>
              <Drawer open={state['left']} onClose={toggleDrawer('left', false)} classes={{ paper: classes.paper }}>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={toggleDrawer("left", false)}
                  onKeyDown={toggleDrawer("left", false)}
                  classes={{ root: classes.root }}
                >
                  {list('left')}
                </div>
              </Drawer>
            </React.Fragment>
        </Header>
        <Content
            style={{
                margin: '24px 16px',
                padding: 24,
                background: '#fff',
                // height: 'calc(235vh - 112px)',
                backgroundColor: '#f0f2f5'
            }}
          >
            <AppRoutes/>
        </Content>
      </MobileView>
      <BrowserView>
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed} style={{backgroundColor: colors.menuSecondary}}>
            <div className={classes.logo} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
              {!collapsed &&
                <img src={logo.default} alt="" style={{
                  width: '50%',
                  // paddingLeft: '10px',
                  // paddingRight: '10px'
                }}/>
              }
              {collapsed &&
                <img src={logoSmall.default} alt="" style={{
                  width: '100%',
                  // paddingLeft: '10px',
                  // paddingRight: '10px'
                }}/>
              }
              {/* <span style={{color: 'white', fontWeight: 700, fontSize: '15px'}}>BKA PARKING SYSTEM</span> */}
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={handleClick} selectedKeys={[current]} style={{backgroundColor: colors.menuSecondary}}>
              <Menu.Item key="1" style={{backgroundColor: current === '1' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/dashboard">
                  <DashboardOutlined />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
              {/*<Menu.Item key="2" style={{backgroundColor: current === '2' ? colors.primary : colors.menuSecondary}}>*/}
              {/*  <Link to="/app/detection">*/}
              {/*    <UserOutlined />*/}
              {/*    <span>Detect Plate Number</span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
              <Menu.Item key="2a" style={{backgroundColor: current === '2a' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/live_camera">
                  <VideoCameraFilled />
                  <span>Live Camera</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="2b" style={{backgroundColor: current === '2b' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/parking_spot">
                  <FontAwesomeIcon icon={faMapMarker} style={{marginRight:10}}/>
                  <span>Parking Area</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="3" style={{backgroundColor: current === '3' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/carin">
                  <FontAwesomeIcon icon={faShip} style={{marginRight:10}}/>
                  <span>Ship in</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4" style={{backgroundColor: current === '4' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/carout">
                  <FontAwesomeIcon icon={faShip} style={{marginRight:10}}/>
                  <span>Ship Out</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4a" style={{backgroundColor: current === '4a' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/manifests">
                  <FontAwesomeIcon icon={faFileText} style={{marginRight:10}}/>
                  <span>Manifest</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="5" style={{backgroundColor: current === '5' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/matchingresult">
                  <BlockOutlined />
                  <span>Matching Result</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="6" style={{backgroundColor: current === '6' ? colors.primary : colors.menuSecondary}}>
                <Link to="/app/device_status">
                  <FontAwesomeIcon icon={faVideoCamera} style={{marginRight:10}}/>
                  <span>Device Status</span>
                </Link>
              </Menu.Item>
              {/*<Menu.Item key="2">*/}
              {/*  <Link to="/app/master">*/}
              {/*    <UserOutlined />*/}
              {/*    <span>Master</span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
              {/*<Menu.Item key="3">*/}
              {/*  <Link to="/app/inquiry">*/}
              {/*    <UserOutlined />*/}
              {/*    <span>Inquiry</span>*/}
              {/*  </Link>*/}
              {/*</Menu.Item>*/}
            </Menu>
          </Sider>
          <Layout>
            <Header style={{ background: '#fff', padding: 0 }}>
              {!collapsed && <MenuFoldOutlined className={classes.hideSidebarButton} onClick={() => {
                setCollapsed(true);
              }}/>}
              {collapsed && <MenuUnfoldOutlined className={classes.hideSidebarButton} onClick={() => {
                setCollapsed(false);
              }}/>}
            </Header>
            <Content
              style={{
                  margin: '24px 16px',
                  padding: 24,
                  background: '#fff',
                  height: 'calc(100vh - 112px)',
                  backgroundColor: '#f0f2f5'
              }}
            >
              <AppRoutes/>
            </Content>
          </Layout>
        </Layout>
      </BrowserView>

  </div>
  );
};
