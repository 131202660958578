import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import {
  Card,
  Button as ButtonBot,
  CardBody,
} from 'reactstrap';
import { Table, Menu, Input, Button, Icon, Dropdown, Progress, Card as CardReact, Empty, Row, Col, Typography, Modal} from 'antd';
import { useStore } from "../../utils/useStores";
import 'antd/dist/antd.css';
import { CarFilled, RightCircleFilled, LeftCircleFilled, BlockOutlined } from '@ant-design/icons';
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import Highlighter from 'react-highlight-words';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {appConfig} from "../../config/app";
import {format} from "date-fns";
import {observer} from "mobx-react-lite";
import { Chart } from "react-google-charts";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import {LiveCCTV} from "./LiveCCTV";
import { useHistory } from 'react-router-dom';


export const Dashboard = observer(() => {
  const classes = useStyles();

  const store = useStore();
  const history = useHistory();

  const [state, setState] = useState({
    dataRevenue: '20',
    dataTraffic: '10',
    dataAccident: '',
  })

  const [plateNumber, setPlateNumber] = useState('');
  const [visible, setVisible] = useState(false)
	const [link, setLink] = useState('');

  useEffect(() => {
    store.dashboard.getData();
  }, []);

  const columns = [
    {
      title: 'Plate Number',
      dataIndex: 'input_plate_number',
      key: 'input_plate_number',
      // render: text => <a>{text}</a>,
      render: (text, data) =>{
        return(
            <div>{data.input_plate_number} | {data.output_plate_number}</div>
        );
      },
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (text, data) =>{
        return(
            <div>{data.input_color} | {data.output_color}</div>
        );
      },
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: (text, data) =>{
        return(
            <div>{data.input_brand} | {data.output_brand}</div>
        );
      },
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (text, data) =>{
        return(
            <div>{data.input_model} | {data.output_model}</div>
        );
      },
    },
    {
      title: 'Match Percentage',
      dataIndex: 'match_percentage',
      key: 'match_percentage',
      render: (text, data) =>{
        return(
            <div>{data.match_percentage}</div>
        );
      },
    },
    {
      title: 'Image',
      dataIndex: 'image_path',
      key: 'image_path',
      // render: text => <a href={appConfig.apiUrl + text} target={'_blank'}>Image</a>,
      render: (text, data) => {
        return (<>
          <Button onClick={()=>showModal(data.input_image_path)}>Image Input</Button>
          <Button onClick={()=>showModal(data.output_image_path)}>Image Output</Button>
        </>);
      }
    },
    {
      title: 'Datetime',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (text) => {
        return format(new Date(text), 'dd/MM/yyyy HH:mm:ss');
      }
    },
  ];

  const showModal = (linkPath) => {
		setVisible(true);
		setLink(linkPath)
  };

  const handleOk = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

	const handleCancel = e => {
		console.log(e);
		setVisible(false);
		setLink(null);
	};

  const emptyChartWeb = (title, desc) => {
    return (
      <div style={{
        minHeight: 'calc(100vh-450px)'
      }}>
        <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10}}>
          <CardBody className={'d-flex flex-row align-items-center flex-0'}>
            <div className="flex">
              <Row>
                <Col span={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'}}>
                  <div style={{
                      backgroundColor: desc,
                      // backgroundColor: '#fff1d4',
                      borderRadius: '50%',
                      width: '70px',
                      height: '70px',
                  }}>
                    {title === 'Car In' &&
                      <LeftCircleFilled style={{color: '#fbc434', fontSize: '30px', padding: '20px'}} /> }

                    {title === 'Car Out' &&
                      <RightCircleFilled style={{color: '#db4437', fontSize: '30px', padding: '20px'}} /> }

                    {title === 'Matching Result' &&
                      <BlockOutlined style={{color: '#4185f4', fontSize: '30px', padding: '20px'}} />
                    }

                    {title === 'Car' &&
                      <CarFilled style={{color: 'white', fontSize: '30px', padding: '20px'}} /> }
                  </div>
                </Col>
                <Col span={12}>
                  <Typography.Title style={{
                      fontSize: 40,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: 0,

                  }}>0</Typography.Title>
                  <div className="d-flex flex-row align-items-start flex-1 w-100 justify-content-between">
                    <div className="d-flex flex-1 ">
                      <p className="mb-0" style={{display: 'flex', justifyContent: 'flex-start' }}><strong>Total {title}</strong>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  const emptyChartMobile = (title, desc) => {
    return (
      <div style={{
        minHeight: 'calc(100vh-450px)'
      }}>
        <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10}}>
          <CardBody className={'d-flex flex-row align-items-center flex-0'}>
            <div className="flex">
              <Row gutter={[16, 16]}>
                <Col span={24} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'}}>
                  <div style={{
                      backgroundColor: desc,
                      // backgroundColor: '#fff1d4',
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                  }}>
                    {title === 'Car In' &&
                      <LeftCircleFilled style={{color: '#fbc434', fontSize: '20px', padding: '5px'}} /> }

                    {title === 'Car Out' &&
                      <RightCircleFilled style={{color: '#db4437', fontSize: '20px', padding: '5px'}} /> }

                    {title === 'Matching Result' &&
                      <BlockOutlined style={{color: '#4185f4', fontSize: '20px', padding: '5px'}} />
                    }

                    {title === 'Car' &&
                      <CarFilled style={{color: 'white', fontSize: '20px', padding: '5px'}} /> }
                  </div>
                </Col>
                <Col span={24}>
                  <p style={{
                      fontSize: 20,
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 700

                  }}>0</p>
                  <div className="d-flex flex-row align-items-start flex-1 w-100 justify-content-between">
                    <div className="d-flex flex-1 ">
                      <p className="mb-0" style={{display: 'flex', justifyContent: 'flex-start', fontSize: 12, height: '35px' }}><strong>Total {title}</strong>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }


  const renderCardWeb = (title, desc, color, count=0, target='#') => {
    let onClickData = {};


    let extraStyle = {};
    if(target !== '#'){
      extraStyle = {
        cursor: 'pointer'
      }
      onClickData = {
        onClick: ()=>history.push(target)
      }
    }

    return (
      <div style={{
        minHeight: 'calc(100vh-450px)'
      }}>
        <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10, ...extraStyle}} {...onClickData}>
          <CardBody className={'d-flex flex-row align-items-center flex-0'}>
            <div className="flex">
              <Row>
                <Col span={12} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'}}>
                  <div style={{
                      backgroundColor: desc,
                      // backgroundColor: '#fff1d4',
                      borderRadius: '50%',
                      width: '70px',
                      height: '70px',
                  }}>
                    {title === 'Car In' &&
                      <LeftCircleFilled style={{color: '#fbc434', fontSize: '30px', padding: '20px'}} /> }

                    {title === 'Car Out' &&
                      <RightCircleFilled style={{color: '#db4437', fontSize: '30px', padding: '20px'}} /> }

                    {title === 'Matching Result' &&
                      <BlockOutlined style={{color: '#4185f4', fontSize: '30px', padding: '20px'}} />
                    }

                    {title === 'Car' &&
                      <CarFilled style={{color: 'white', fontSize: '30px', padding: '20px'}} /> }
                  </div>
                </Col>
                <Col span={12}>
                  <Typography.Title style={{
                      fontSize: 40,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: 0,
                  }}>{count}</Typography.Title>
                  <div className="d-flex flex-row align-items-start flex-1 w-100 justify-content-between">
                    <div className="d-flex flex-1 ">
                      <p className="mb-0" style={{display: 'flex', justifyContent: 'flex-start' }}><strong>{title}</strong>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  const renderCardMobile = (title, desc, a, count=0) => {
    return (
      <div style={{
        minHeight: 'calc(100vh-450px)'
      }}>
        <Card className={'shadow'} style={{borderBottomColor: 'red', borderWidth: 10}}>
          <CardBody className={'d-flex flex-row align-items-center flex-0'}>
            <div className="flex">
              <Row gutter={[16, 16]}>
                <Col span={24} style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'}}>
                  <div style={{
                      backgroundColor: desc,
                      // backgroundColor: '#fff1d4',
                      borderRadius: '50%',
                      width: '30px',
                      height: '30px',
                  }}>
                    {title === 'Car In' &&
                      <LeftCircleFilled style={{color: '#fbc434', fontSize: '20px', padding: '5px'}} /> }

                    {title === 'Car Out' &&
                      <RightCircleFilled style={{color: '#db4437', fontSize: '20px', padding: '5px'}} /> }

                    {title === 'Matching Result' &&
                      <BlockOutlined style={{color: '#4185f4', fontSize: '20px', padding: '5px'}} />
                    }

                    {title === 'Car' &&
                      <CarFilled style={{color: 'white', fontSize: '20px', padding: '5px'}} /> }
                  </div>
                </Col>
                <Col span={24}>
                  <p style={{
                      fontSize: 20,
                      display: 'flex',
                      justifyContent: 'center',
                      fontWeight: 700

                  }}>{count}</p>
                  <div className="d-flex flex-row align-items-start flex-1 w-100 justify-content-between">
                    <div className="d-flex flex-1 ">
                      <p className="mb-0" style={{display: 'flex', justifyContent: 'flex-start', fontSize: 12, height: '35px' }}><strong>Total {title}</strong>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  return <div>
    <div style={{flexGrow: 1}}>
      <MobileView>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <Paper className={classes.paper}>
            {renderCardMobile("Car In", '#ffeede', '#ffefcc', store.dashboard.data.in_count)}
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={classes.paper}>
            {renderCardMobile("Matching Result", '#bdd6ff', '#e8e8e8', store.dashboard.data.match_count)}
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={classes.paper}>
              {state.dataRevenue ?
                renderCardMobile("Car Out", '#facfd4', '#ffbbc1', store.dashboard.data.out_count)
                :
                emptyChartMobile("Car Out", '#facfd4', '#ffbbc1')
              }
            </Paper>
          </Grid>
        </Grid>
      </MobileView>

      <BrowserView>
        <LiveCCTV />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
            {renderCardWeb("Car In", '#ffeede', '#ffefcc', store.dashboard.data.in_count,'/app/carin')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
            {renderCardWeb("Matching Result", '#bdd6ff', '#e8e8e8', store.dashboard.data.match_count,'/app/matchingresult')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Car Out", '#facfd4', '#ffbbc1', store.dashboard.data.out_count,'/app/carout')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Data", '#bdd6ff', '#e8e8e8', store.dashboard.dataQuality.total_count,'/app/matchingresult')}
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Pending", '#bdd6ff', '#e8e8e8', store.dashboard.dataQuality.pending)}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Valid", '#ffeede', '#ffefcc', store.dashboard.dataQuality.valid)}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Partially Valid", '#bdd6ff', '#e8e8e8', store.dashboard.dataQuality.partial_valid)}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Invalid", '#facfd4', '#ffbbc1', store.dashboard.dataQuality.invalid)}
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Pending (%)", '#bdd6ff', '#e8e8e8', (store.dashboard.dataQuality.pending_percentage * 100).toFixed(2) + '%')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Valid (%)", '#ffeede', '#ffefcc', (store.dashboard.dataQuality.valid_percentage * 100).toFixed(2) + '%')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Partially Valid (%)", '#bdd6ff', '#e8e8e8', (store.dashboard.dataQuality.partial_valid_percentage * 100).toFixed(2) + '%')}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Paper className={classes.paper}>
              {renderCardWeb("Invalid (%)", '#facfd4', '#ffbbc1', (store.dashboard.dataQuality.invalid_percentage * 100).toFixed(2) + '%')}
            </Paper>
          </Grid>
        </Grid>
      </BrowserView>

      <Grid container spacing={2} style={{paddingTop: '10px'}}>
        <Grid item xs={12} sm={8}>
          <Paper className={classes.paper}>
          <Chart
            width={'100%'}
            height={'300px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={[
              ['month', 'Matching Result', 'Car Out', 'Car In', ],
              ['Agu', 1000, 400, 200],
              ['Sep', 1170, 460, 250],
              ['Oct', 660, 1120, 300],
              ['NOv', 1030, 540, 350],
            ]}
            options={{
              // Material design options
              chart: {
                title: 'Car Detection',
                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
              },
            }}
            // For tests
            rootProps={{ 'data-testid': '2' }}
          />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
          <Chart
            width={'100%'}
            height={'300px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ['Total Car', 'Hours per Day'],
              ['Matching Result', 2],
              ['Car Out', 2],
              ['Car In', 11],
            ]}
            options={{
              title: 'Daily Car',
              // Just add this option
              pieHole: 0.4,
            }}
            rootProps={{ 'data-testid': '3' }}
          />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{paddingTop: '10px'}}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Table scroll={{ x: 'calc(50vh - 4em)' }} dataSource={store.dashboard.dataMatching.slice()} columns={columns} size="middle" style={{width: '100%'}} />
          </Paper>
        </Grid>
      </Grid>
      <Modal
				title="Image"
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<img src={appConfig.apiUrl + link} alt="" style={{
					width: '100%',
					height: '100%',
					padding: '10%',
					borderRadius: 15,
				}}/>
			</Modal>
      </div>
  </div>
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 5
  },
}));
